
import React from "react";
const Total = (props)=>{
    let {mounthlyPayment,cashback,credit,percent,method}= props;

    return(
       <div className="total">
            <div className="indincators">
                <div className="first-wrap">
                    <div className="indicator-wrapper">
                        <p className="indicator-label">Ежемесячный платеж</p>
                        <p className="indicator-value pay">{method(Math.ceil(mounthlyPayment))} ₽</p>
                    </div>
                    <div className="indicator-wrapper">
                        <p className="indicator-label">Налоговый вычет</p>
                        <p className="indicator-value cashback">{method(Math.ceil(cashback))} ₽</p>
                    </div>
                </div>
                <div className="second-wrap">
                <div className="indicator-wrapper">
                        <p className="indicator-label">Сумма кредита</p>
                        <p className="indicator-value">{method(Math.ceil(credit))} ₽</p>
                    </div>
                    <div className="indicator-wrapper">
                        <p className="indicator-label">Процентная ставка</p>
                        <p className="indicator-value">{percent}%</p>
                    </div>
                </div>
            </div>
            <div className="button button_large green">Оставить заявку</div>
            <p className="more">Подробнее об условиях</p>
            <p className="text">Стоимость предмета лизинга и приведенные расчеты через калькулятор 
                являются предварительными. Для точного определения процентной ставки по договору, пожалуйста, 
                обратитесь к менеджеру в вашем регионе. Калькулятор не следует применять для расчетов по 44-Ф3 и 223-Ф3.
            </p>
            <div className="payment-methods">
                <div className="payment"><img src="./vectors/rosbank2.svg" alt="Росбанк"/></div>
                <div className="payment"><img src="./vectors/sberbank2.svg" alt="Сбербанк"/></div>
            </div>
       </div>
    )
}
export default Total