import React, { Component } from "react";

class Range extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            progress: this.calculateProgress(this.props.value),
        };
    }

    calculateProgress(value) {
        const { min, max } = this.props;
        return ((value - min) / (max - min)) * 100;
    }
    formatNumber(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    handleInputChange = (event) => {
        const newValue = event.target.value;
        this.setState({ 
            value: newValue,
            progress: this.calculateProgress(newValue)
        });
        this.props.method(event.target.value);
    }

    render() {
        const { value, progress } = this.state;
        const { max, min, id, minLabel, maxLabel, label, renderLabel,renderedValue } = this.props;
        let maxWidth = '100%'
        progress >=80?maxWidth = (progress-1)+"%":maxWidth = "100%";
        return (
            <div className="wrap">
                <p className="min">{minLabel}</p>
                <p className="max">{maxLabel}</p>
                <div className="input-wrapper">
                    <span className="percent">{this.props.percent}</span>
                    <div className="border-bottom "></div>
                    <div className="progress" style={{ width: `${progress}%`, maxWidth:maxWidth}}></div>
                    <label htmlFor={id}>{label}</label>
                    <span>{this.formatNumber(renderedValue)}{" "}{renderLabel}</span>
                </div>
                <input 
                        type="range" 
                        max={max}
                        defaultValue={value} 
                        step={this.props.step} 
                        min={min}
                        onChange={this.handleInputChange}
                        id={id}
                    />
            </div>
        );
    }
}

export default Range;