import React from "react";
import { createRoot } from 'react-dom/client';
import Calculator from "./reactComponent/calculator";

// Вытаскиваем все контейнеры с классом calculator-container
const domContainers = document.querySelectorAll('.calculator-container');

// Для каждого контейнера создаем и рендерим компонент Calculator
domContainers.forEach((container, index) => {
    const root = createRoot(container);
    root.render(<Calculator id={index} />);
});
